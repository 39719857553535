<template>
  <loading v-if="is_loading" />
  <div v-else class="profile">
    <b-row v-if="pageData">
      <!-- right-side -->
      <b-col sm="12" md="9">
        <b-card
          body-class="pb-0"
          :img-src="filterImages(pageData.media, 'cover_image')"
          :img-alt="pageData.first_name"
          img-top
        >
          <b-avatar
            class="my-avatar"
            size="10rem"
            :src="filterImages(pageData.media, 'path')"
            :img-alt="pageData.first_name"
          />
          <b-card-title class="font-weight-bold" style="font-size: 28px">
            {{ pageData.first_name }}
          </b-card-title>
          <b-row align-h="between">
            <b-col>
              <b-card-sub-title class="mt-0 mb-1">
                {{ $t(pageData.user_type) }}
              </b-card-sub-title>
              <b-card-text v-if="pageData.student">
                {{ pageData.student.eduyear.name }}
              </b-card-text>
            </b-col>
            <b-col cols="auto">
              <b-link to="/edit-profile">
                <b-button :disabled="is_loading" variant="primary">
                  {{ $t("g.EditProfile") }}
                </b-button>
              </b-link>
            </b-col>
          </b-row>
          <hr />

          <!-- Start Tab Header -->
          <b-col cols="12" nav-class="mb-0 wrap">
            <div class="tab-header-scrollable">
              <b-tabs v-model="activeTab" style="overflow-x: auto">
                <b-tab
                  v-for="(tab, index) in tabs"
                  :key="index"
                  :title="$t(`g.${tab.title}`)"
                ></b-tab>
              </b-tabs>
            </div>
          </b-col>
          <!-- End Tab Header -->
        </b-card>

        <!-- Start Tab Body -->
        <b-card>
          <b-tabs v-model="activeTab" nav-class="d-none">
            <b-tab v-for="(tab, index) in tabs" :key="index" lazy>
              <div class="tab-body-title">
                <h4>
                  {{ $t(`g.${tab.title}`) }}
                </h4>
                <img
                  :src="editSvg"
                  alt=""
                  v-if="isEdit && tab.title == 'About'"
                  @click="isEdit = false"
                  style="cursor: pointer"
                />
                <img
                  :src="closeSvg"
                  alt=""
                  v-else-if="tab.title == 'About'"
                  @click="isEdit = true"
                  style="cursor: pointer"
                />
              </div>
              <hr />
              <profile-about-component
                v-if="tab.title == 'About'"
                :isEdit="isEdit"
                :aboutProps="componentsData.about"
              />
              <profile-info-component
                v-if="tab.title == 'Info'"
                :infoProps="componentsData.info"
              />
              <profile-courses-component v-if="tab.title == 'Coureses'" />
            </b-tab>
          </b-tabs>
        </b-card>
        <!-- End Tab Body -->
      </b-col>
      <!-- right-side -->

      <!-- left side -->
      <b-col sm="12" md="3">
        <b-card :title="$t('g.Account')">
          <hr />
          <b-card-sub-title class="mt-0">
            {{ $t("g.Joined") }}
          </b-card-sub-title>
          <b-card-text>{{ convertDate(pageData.created_at) }}</b-card-text>
        </b-card>
        <profile-childs-card-component
          v-if="childsData"
          @removeChild="removeChild"
          @updateData="updateData"
          :childsCardProps="childsData"
        />
        <profile-friends-card-component
          v-if="friendsData && friendsData.length"
          :friendsCardProps="friendsData"
        />
      </b-col>
      <!-- left side -->
    </b-row>
  </div>
</template>

<script>
import Loading from "@/components/loading/loading.vue";
import ProfileInfoComponent from "@/components/profile/ProfileInfoComponent";
import ProfileAboutComponent from "@/components/profile/ProfileAboutComponent";
import ProfileCoursesComponent from "@/components/profile/ProfileCoursesComponent";
import ProfileFriendsCardComponent from "@/components/profile/ProfileFriendsCardComponent";
import ProfileChildsCardComponent from "@/components/profile/ProfileChildsCardComponent";
import {
  BCard,
  BCardText,
  BLink,
  BRow,
  BCol,
  BCardSubTitle,
  BButton,
  BListGroupItem,
  BAvatar,
  BTabs,
  BTab,
  BCardTitle,
} from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    BTab,
    BCard,
    BLink,
    BTabs,
    BButton,
    BAvatar,
    Loading,
    BCardText,
    BCardTitle,
    BCardSubTitle,
    BListGroupItem,
    ProfileInfoComponent,
    ProfileAboutComponent,
    ProfileCoursesComponent,
    ProfileFriendsCardComponent,
    ProfileChildsCardComponent,
  },
  data() {
    return {
      isError: false,
      is_loading: true,
      pageData: null,
      childsData: null,
      friendsData: null,
      activeTab: 0,
      isEdit: false,
      closeSvg: require("@/assets/images/icons/close.svg"),
      editSvg: require("@/assets/images/icons/edit.svg"),
      profileImgaePlaceholder: require("@/assets/images/profileAvatar.png"),
      profileCoverPlaceholder: require("@/assets/images/profileCover.png"),
      componentsData: {
        about: null,
        info: null,
        courses: null,
      },
      tabs: [{ title: "About" }, { title: "Info" }, { title: "Coureses" }],
    };
  },
  created() {
    this.getStudentData();
    const courseId = window.sessionStorage.getItem("ci");
    if (courseId) {
      this.addToCart(courseId);
    }
  },
  methods: {
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },
    async getStudentData() {
      try {
        this.is_loading = true;
        const userRes = await this.$http.get("/auth/guardian/profile");
        const data = userRes.data.data.user;
        this.pageData = data;
        this.childsData = data.child;
        this.friendsData = data.friends;
        this.assignComponentsData(data);
      } catch (error) {
        for (const key in error.response?.data?.errors) {
          this.makeToast(
            "danger",
            error.response.data.errors[key][0],
            this.$t("g.send.errorTitle")
          );
        }
      } finally {
        this.is_loading = false;
      }
    },
    assignComponentsData(data) {
      this.componentsData = {
        ...this.componentsData,
        about: data.about,
        info: {
          email: data.email,
          name: data.first_name,
          phone: data.phone?.length ? data.phone[0].phone : null,
          country: data.country.name,
          birthday: data.birthdate,
        },
      };
    },

    filterImages(data, name) {
      const placeholderImage =
        name === "path"
          ? this.profileImgaePlaceholder
          : this.profileCoverPlaceholder;

      if (data) {
        const matchingImage = data.find((image) => image.name === name);
        return matchingImage ? matchingImage.path : placeholderImage;
      }

      return placeholderImage;
    },

    convertDate(date) {
      // Convert the timestamp to a Date object
      const dateObject = new Date(date);

      // Desired output format
      const options = { year: "numeric", month: "short", day: "numeric" };

      // Convert the Date object to the desired output format
      const desiredOutput = dateObject.toLocaleDateString("en-US", options);

      return desiredOutput;
    },

    async updateData() {
      this.getStudentData();
    },

    removeChild(childId) {
      this.childsData = this.childsData.filter(
        (item) => item?.student?.id !== childId
      );
    },
    async addToCart(courseId) {
      try {
        this.is_loading = true;
        const formData = new FormData();
        formData.append("course_id", courseId);
        formData.append("qty", 1);
        await this.$http.post("/guardian/cart", formData);
        this.$helpers.makeToast(
          "success",
          this.$t("success"),
          this.$t("g.course_added_to_cart_successfully")
        );
        sessionStorage.removeItem("ci");
        this.$router.push("/cart");
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.is_loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "./profile.scss";
</style>
