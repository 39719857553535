<template>
  <overlay-component v-if="isLoading" :isLoading="true" />
  <div
    style="gap: 23px"
    class="d-flex flex-column"
    v-else-if="coursesData && coursesData.length > 0"
  >
    <b-row
      align-h="start"
      align-v="center"
      v-for="course in coursesData"
      :key="course.id"
    >
      <b-col cols="auto">
        <img
          width="45"
          height="45"
          alt="Example Image"
          style="border-radius: 8px; object-fit: cover"
          :src="
            course.itemable.image && course.itemable.image[0]
              ? course.itemable.image[0].path
              : imageSrc
          "
        />
      </b-col>
      <b-col class="p-0 my-1 mx-2" style="white-space: noWrap">
        <b-row>{{ course.itemable.name }}</b-row>
        <b-row style="white-space: noWrap">
          <span v-if="course.itemable.lessons_count !== undefined">
            {{ `${course.itemable.lessons_count} ${$t("g.classes")}` }}
          </span>
          {{ ` . ${$t("g.owned")}` }}
          <span style="color: #f5822a">
            {{ `: ${course.license_count}` }}
          </span>
        </b-row>
      </b-col>
      <b-col v-if="course.license_count > 0" cols="auto" class="my-1">
        <b-button
          variant="primary"
          @click="$bvModal.show(`bv-modal-assign-child-${course.id}`)"
        >
          {{ $t("g.assign_to") }}
        </b-button>
        <assign-to-modal
          :childsData="childsData"
          :courseId="course.id"
          @updateData="getCoursesData"
        />
      </b-col>
    </b-row>
    <b-row
      align-h="start"
      align-v="center"
      v-for="course in assignedCoursesData"
      :key="course.id"
    >
      <b-col cols="auto">
        <img
          width="45"
          height="45"
          alt="Example Image"
          style="border-radius: 8px; object-fit: cover"
          :src="
            course.course.image && course.course.image[0]
              ? course.course.image[0].path
              : imageSrc
          "
        />
      </b-col>
      <b-col class="p-0 my-1 mx-2" style="white-space: noWrap">
        <b-row>{{ course.course.name }}</b-row>
        <b-row style="white-space: noWrap">
          {{ `${course.course.lessons_count} ${$t("g.classes")}` }}
          {{ ` . ${$t("g.assigned_to")}` }}
          <span style="color: #f5822a">
            {{ `: ${course.student}` }}
          </span>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="m-0">
      <b-link to="/">
        <div
          class="add-classroom d-flex justify-content-center align-items-center"
        >
          <img :src="plusIcon" alt="Add Course" />
        </div>
      </b-link>
    </b-row>
  </div>
  <div class="d-flex flex-column" v-else>
    <b-card-text class="mb-2">
      {{ $t("g.ThereIsCurrentlyNoCourses") }}
    </b-card-text>
    <b-row class="m-0">
      <b-link to="/">
        <div
          class="add-classroom d-flex justify-content-center align-items-center"
        >
          <img :src="plusIcon" alt="Add Course" />
        </div>
      </b-link>
    </b-row>
  </div>
</template>

<script>
import OverlayComponent from "@/components/shared/OverlayComponent";
import { BCardText, BRow, BCol, BLink, BButton } from "bootstrap-vue";
import AssignToModal from "@/components/profile/ProfileCoursesComponent/AssignToModal";

export default {
  components: {
    BCol,
    BLink,
    BRow,
    BButton,
    BCardText,
    AssignToModal,
    OverlayComponent,
  },
  data() {
    return {
      isLoading: true,
      coursesData: [],
      assignedCoursesData: [],
      childsData: [],
      imageSrc: require("@/assets/dev/Rectangle 12.png"),
      plusIcon: require("@/assets/images/icons/plus.svg"),
    };
  },
  created() {
    this.getCoursesData();
    this.getChilds();
  },
  methods: {
    async getCoursesData() {
      try {
        this.isLoading = true;
        const res = await this.$http.get("/guardian/my-courses");

        const data = res.data.data;
        this.coursesData = data;
        this.assignedCoursesData = [];
        for (let course of data) {
          this.assignedCoursesData.push(...course.assigned);
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
    async getChilds() {
      try {
        this.isLoading = true;
        const res = await this.$http.get("/guardian/childs");
        this.childsData = res.data.data;
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
@import "./_index.scss";
</style>
