<template>
  <div class="child-card">
    <b-card :title="$t('g.childrens')" v-if="childsCardProps">
      <hr />
      <b-row
        class="flex-nowrap align-items-center justify-content-between mb-1"
        v-for="(child, index) in childsCardProps"
        :key="index"
      >
        <b-link
          class="d-flex align-items-center"
          v-if="child.user && child.user.user_type === 'student'"
          :to="`/show-student/${child.user.type_id}`"
        >
          <b-avatar
            class="mx-1"
            :src="
              child.user.media && child.user.media.length > 0
                ? child.user.media[0].path
                : $helpers.uiAvatar(child.user.first_name)
            "
          />
          <span class="user-name">{{
            `${child.user.first_name} ${child.user.last_name}`
          }}</span>
        </b-link>
        <button
          @click="deleteChild(child.user.id)"
          style="all: unset; cursor: pointer"
          v-if="child.user && child.user.user_type === 'student'"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 12 12"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.57613 2.57613C2.81044 2.34181 3.19034 2.34181 3.42465 2.57613L6.00039 5.15186L8.57613 2.57613C8.81044 2.34181 9.19034 2.34181 9.42465 2.57613C9.65897 2.81044 9.65897 3.19034 9.42465 3.42465L6.84892 6.00039L9.42465 8.57613C9.65897 8.81044 9.65897 9.19034 9.42465 9.42465C9.19034 9.65897 8.81044 9.65897 8.57613 9.42465L6.00039 6.84892L3.42465 9.42465C3.19034 9.65897 2.81044 9.65897 2.57613 9.42465C2.34181 9.19034 2.34181 8.81044 2.57613 8.57613L5.15186 6.00039L2.57613 3.42465C2.34181 3.19034 2.34181 2.81044 2.57613 2.57613Z"
              fill="#DD2222"
            />
          </svg>
        </button>
      </b-row>
      <b-row
        class="align-items-center mb-1 mt-2"
        @click.stop="$bvModal.show('bv-modal-add-child')"
      >
        <button class="mx-1 add-child-icon">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10 3C10.5523 3 11 3.44772 11 4V9H16C16.5523 9 17 9.44772 17 10C17 10.5523 16.5523 11 16 11H11V16C11 16.5523 10.5523 17 10 17C9.44772 17 9 16.5523 9 16V11H4C3.44772 11 3 10.5523 3 10C3 9.44771 3.44772 9 4 9L9 9V4C9 3.44772 9.44772 3 10 3Z"
              fill="#5E5E5E"
            />
          </svg>
        </button>
        <span class="user-n ame">
          {{ $t("g.add_child") }}
        </span>
      </b-row>
      <overlay-component :isLoading="isloading" />
    </b-card>
    <AddChildModal @updateData="updateData" />
  </div>
</template>

<script>
import AddChildModal from "./AddChildModal";
import { BCard, BRow, BAvatar, BLink } from "bootstrap-vue";
import OverlayComponent from "@/components/shared/OverlayComponent";

export default {
  name: "ProfileChildsCardComponent",
  components: {
    BRow,
    BCard,
    BAvatar,
    BLink,
    AddChildModal,
    OverlayComponent,
  },
  props: ["childsCardProps"],
  data() {
    return {
      isloading: false,
    };
  },
  methods: {
    async deleteChild(childId) {
      try {
        this.isloading = true;
        let formData = new FormData();
        formData.append("student_id[0]", childId);
        await this.$http.post(`/guardian/update`, formData);
        this.$emit("removeChild", childId);
        this.$helpers.makeToast(
          "success",
          this.$t("g.success"),
          this.$t("g.child_removed_successfully")
        );
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.isloading = false;
      }
    },
    async updateData() {
      await this.$emit("updateData");
    },
  },
};
</script>
<style lang="scss">
@import "./_index.scss";
</style>
