<template>
  <b-modal
    :id="`bv-modal-assign-child-${courseId}`"
    :title="$t('g.assign_child_to_course')"
    dialog-class="bv-modal-assign-child"
    hide-footer
    centered
    lazy
  >
    <validation-observer ref="assignChildForm">
      <b-form>
        <b-row class="justify-content-center">
          <!-- Start Course Email Input -->
          <b-col cols="12">
            <b-form-group label-for="email" class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required"
              >
                <b-form-select
                  v-model="studentId"
                  :options="childsData"
                  text-field="user.email"
                  value-field="user.type_id"
                >
                  <template #first>
                    <b-form-select-option selected disabled :value="null">
                      {{ $t("g.select_child_email") }}
                    </b-form-select-option>
                  </template>
                </b-form-select>

                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- End Course Email Input -->

          <b-col cols="12" class="modal-actions mt-2">
            <b-button
              :disabled="isLoading"
              class="add-button mr-1"
              @click.stop="validation"
            >
              {{ $t("g.assign") }}
            </b-button>
            <b-button
              @click.stop="$bvModal.hide(`bv-modal-assign-child-${courseId}`)"
              >{{ $t("g.cancel") }}
            </b-button>
          </b-col>
        </b-row>
        <overlay-component :isLoading="isLoading" />
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BModal,
  BRow,
  BFormSelect,
  BFormGroup,
  BFormSelectOption,
} from "bootstrap-vue";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import OverlayComponent from "@/components/shared/OverlayComponent";

export default {
  name: "AssignToModal",
  props: ["courseId", "childsData"],
  components: {
    BCol,
    BRow,
    BForm,
    BButton,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BFormSelectOption,
    BFormGroup,
    OverlayComponent,
  },
  data() {
    return {
      required,
      studentId: null,
      isLoading: false,
    };
  },
  methods: {
    validation() {
      this.$refs.assignChildForm
        .validate()
        .then((success) => success && this.assignChild());
    },
    assignChild() {
      this.isLoading = true;
      let formData = new FormData();
      formData.append("student_id", this.studentId);
      formData.append("item_id", this.courseId);
      this.$http
        .post(`/guardian/assign-child-course`, formData)
        .then(() => {
          this.$helpers.makeToast(
            "success",
            this.$t("g.success"),
            this.$t("g.child_added_successfully")
          );
          this.$emit("updateData");
          this.$bvModal.hide(`bv-modal-assign-child-${this.courseId}`);
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          this.$helpers.handleError(error);
        });
    },
  },
};
</script>

<style lang="scss">
.bv-modal-assign-child {
  .modal-actions {
    display: flex;
    justify-content: flex-end;
    .add-button {
      background-color: #135991 !important;
      border: none;
    }
  }
}
</style>
