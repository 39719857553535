<template>
  <b-card class="friends-card" :title="$t('g.Friends')">
    <hr />
    <b-row
      class="align-items-center"
      v-for="(friend, index) in friendsCardProps"
      :key="index"
    >
      <div
        class="mb-2"
        v-if="
          friend.friend &&
          friend.friend.id !== $store.state.userData.userId &&
          friend.friend.type !== 'admin'
        "
      >
        <b-link
          @click="redirectUser(friend.friend.type, friend.friend.type_id)"
        >
          <b-avatar
            :src="
              friend.friend.media && friend.friend.media.length
                ? friend.friend.media[0].path
                : $helpers.uiAvatar(friend.friend.first_name)
            "
            class="mx-1"
          />
          <span class="user-name">{{
            `${friend.friend.first_name} ${friend.friend.last_name}`
          }}</span>
        </b-link>
      </div>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BAvatar, BLink } from "bootstrap-vue";
export default {
  name: "ProfileFriendsCardComponent",
  components: {
    BRow,
    BCard,
    BAvatar,
    BLink,
  },
  props: ["friendsCardProps"],
  data() {
    return {
      userId: null,
      profileImgaePlaceholder: require("@/assets/dev/profile_image_placeholder.png"),
    };
  },
  methods: {
    redirectUser(userType, id) {
      if (userType !== "admin") {
        switch (userType) {
          case "student":
            this.$router.push(`/show-student/${id}`);
            break;
          case "guardian":
            this.$router.push(`/show-parent/${id}`);
            break;
          case "teacher":
            this.$router.push(`/show-teacher/${id}`);
            break;
          default:
            this.$router.push("/");
            break;
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "./index.scss";
</style>
