<template>
  <b-modal
    id="bv-modal-add-child"
    :title="$t('g.add_child')"
    hide-footer
    centered
    lazy
  >
    <validation-observer ref="addChildForm">
      <b-form>
        <b-row class="justify-content-center">
          <!-- Start Course Email Input -->
          <b-col cols="12">
            <b-form-group label-for="email" class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required"
              >
                <b-form-input
                  v-model="email"
                  :placeholder="$t('g.child_email')"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- End Course Email Input -->

          <b-col cols="12" class="modal-actions mt-2">
            <b-button
              :disabled="isLoading"
              class="add-button mr-1"
              @click.stop="validation"
            >
              {{ $t("g.add") }}
            </b-button>
            <b-button @click.stop="$bvModal.hide('bv-modal-add-child')"
              >{{ $t("g.cancel") }}
            </b-button>
          </b-col>
        </b-row>
        <overlay-component :isLoading="isLoading" />
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BModal,
  BRow,
  BFormInput,
  BFormGroup,
} from "bootstrap-vue";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import OverlayComponent from "@/components/shared/OverlayComponent";

export default {
  name: "AddChildModal",
  components: {
    BCol,
    BRow,
    BForm,
    BButton,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BFormGroup,
    OverlayComponent,
  },
  data() {
    return {
      required,
      email: null,
      isLoading: false,
    };
  },
  methods: {
    validation() {
      this.$refs.addChildForm
        .validate()
        .then((success) => success && this.addChild());
    },
    addChild() {
      this.isLoading = true;
      let formData = new FormData();
      formData.append("student_emails[0]", this.email);
      this.$http
        .post(`/guardian/childs`, formData)
        .then(() => {
          this.$helpers.makeToast(
            "success",
            this.$t("g.success"),
            this.$t("g.child_added_successfully")
          );
          this.$emit("updateData");
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$helpers.handleError(error);
        });
    },
  },
};
</script>

<style lang="scss">
#bv-modal-add-child {
  .modal-actions {
    display: flex;
    justify-content: flex-end;
    .add-button {
      background-color: #135991 !important;
      border: none;
    }
  }
}
</style>
